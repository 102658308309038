
import {defineComponent, inject} from 'vue';
import FooterBar from 'src/components/FooterBar.vue';
import DrawerMenu from 'src/components/DrawerMenu.vue';

export default defineComponent({
  name: 'IndexLayout',
  components: {DrawerMenu, FooterBar},

  setup() {
    const drawer = inject('drawer');
    return {
      drawer,
    };
  },
});
